// Takes field names and makes them human readable
angular
  .module("sq.jobs.search.fields.service", [])
  .factory("searchFields", fieldNamesFactory);

function fieldNamesFactory() {
  const searchState = {};

  const _fieldNames = {};

  const defaultFieldsNotSearched = {
    discordant: 1,
    "refSeq.clinvar.clinicalSignificance": 1,
    "refSeq.clinvar.numberSubmitter": 1,
    "refSeq.clinvar.reviewStatus": 1,
    "refSeq.clinvar.chromStart": 1,
    "refSeq.clinvar.chromEnd": 1,
    "refSeq.clinvar.type": 1,
    "ensembl.clinvar.clinicalSignificance": 1,
    "ensembl.clinvar.numberSubmitter": 1,
    "ensembl.clinvar.reviewStatus": 1,
    "ensembl.clinvar.chromStart": 1,
    "ensembl.clinvar.chromEnd": 1,
    "ensembl.clinvar.type": 1,
    "dbSNP.class": 1,
    "dbSNP.func": 1,
    "clinvar.type": 1,
    "dbSNP.alleles": 1,
    "dbSNP.observed": 1,
    "clinvar.alternateAllele": 1,
    "clinvar.referenceAllele": 1,
    // 'gnomad.genomes.af': 1,
    // 'gnomad.genomes.af_male': 1,
    // 'gnomad.genomes.af_female': 1,
    // 'gnomad.genomes.af_oth': 1,
    // 'gnomad.genomes.af_sas': 1,
    // 'gnomad.genomes.af_eas': 1,
    // 'gnomad.genomes.trTv': 1,
    // 'gnomad.genomes.af_fin': 1,
    // 'gnomad.genomes.af_amr': 1,
    // 'gnomad.genomes.af_afr': 1,
    // 'gnomad.genomes.af_asj': 1,
    // 'gnomad.genomes.af_nfe': 1,
    // 'gnomad.genomes.an': 1,
    // 'gnomad.genomes.an_male': 1,
    // 'gnomad.genomes.an_female': 1,
    // 'gnomad.genomes.an_oth': 1,
    // 'gnomad.genomes.an_sas': 1,
    // 'gnomad.genomes.an_eas': 1,
    // 'gnomad.genomes.an_fin': 1,
    // 'gnomad.genomes.an_amr': 1,
    // 'gnomad.genomes.an_anr': 1,
    // 'gnomad.genomes.an_asj': 1,
    // 'gnomad.genomes.an_nfe': 1,
    // 'gnomad.exomes.af': 1,
    // 'gnomad.exomes.af_male': 1,
    // 'gnomad.exomes.af_female': 1,
    // 'gnomad.exomes.af_oth': 1,
    // 'gnomad.exomes.af_sas': 1,
    // 'gnomad.exomes.af_eas': 1,
    // 'gnomad.exomes.trTv': 1,
    // 'gnomad.exomes.af_fin': 1,
    // 'gnomad.exomes.af_amr': 1,
    // 'gnomad.exomes.af_afr': 1,
    // 'gnomad.exomes.af_asj': 1,
    // 'gnomad.exomes.af_nfe': 1,
    // 'gnomad.exomes.an': 1,
    // 'gnomad.exomes.an_male': 1,
    // 'gnomad.exomes.an_female': 1,
    // 'gnomad.exomes.an_oth': 1,
    // 'gnomad.exomes.an_sas': 1,
    // 'gnomad.exomes.an_eas': 1,
    // 'gnomad.exomes.an_fin': 1,
    // 'gnomad.exomes.an_amr': 1,
    // 'gnomad.exomes.an_anr': 1,
    // 'gnomad.exomes.an_asj': 1,
    // 'gnomad.exomes.an_nfe': 1,
    // 'gnomad.exomes.an_afr': 1,
    // 'gnomad.genomes.an_afr': 1,
    // 'dbSNP.alleleFreqs': 1,
    // 'cadd': 1,
    // 'phyloP': 1,
    // 'phastCons': 1,
    // 'homozygosity': 1,
    // 'heterozygosity': 1,
    // 'missingness': 1,
    "dbSNP.strand": 1
    // 'trTv': 1,
    // 'sampleMaf': 1,
  };

  Object.keys(defaultFieldsNotSearched).forEach(val => {
    defaultFieldsNotSearched[`${val}.exact`] = 1;
  });

  const defaultBoost = {
    // contains many terms, such as intergenic, which negatively impact relevance
    // 'refSeq.description': 0.5,
    type: 2,
    pos: 2,
    "refSeq.name2": 2,
    "refSeq.siteType": 2,
    "refSeq.exonicAlleleFunction": 2,
    "refSeq.description": 0.5,
    // refSeq.clinvar fields are not as relevant
    "refSeq.clinvar.phenotypeList": 0.5,
    "refSeq.nearest.name2": 0.5,
    "refSeq.nearest.name": 0.5,
    "refSeq.clinvar.clinicalSignificance": 0.5,
    "refSeq.clinvar.origin": 0.5,
    "refSeq.clinvar.type": 0.5,
    "refSeq.clinvar.numberOfSubmitter": 0.5,
    "refSeq.clinvar.alleleID": 0.5,
    "refSeq.clinvar.chromStart": 0.5,
    "refSeq.clinvar.chromEnd": 0.5,
    "refSeq.clinvar.reviewStatus": 0.5,
    "ensembl.name2": 2,
    "ensembl.siteType": 2,
    "ensembl.exonicAlleleFunction": 2,
    // ensembl.clinvar fields are not as relevant
    "ensembl.clinvar.phenotypeList": 0.5,
    "ensembl.nearest.name2": 0.5,
    "ensembl.nearest.name": 0.5,
    "ensembl.clinvar.clinicalSignificance": 0.5,
    "ensembl.clinvar.origin": 0.5,
    "ensembl.clinvar.type": 0.5,
    "ensembl.clinvar.numberOfSubmitter": 0.5,
    "ensembl.clinvar.alleleID": 0.5,
    "ensembl.clinvar.chromStart": 0.5,
    "ensembl.clinvar.chromEnd": 0.5,
    "ensembl.clinvar.reviewStatus": 0.5
  };

  // _fieldNames.hg19 = _fieldNames.hg38 = {
  //   'clinvar.phenotypeList': 'Clinvar Phenotypes',
  //   'clinvar.clinicalSignificance': 'Clinvar Clinical Significance',
  //   'refSeq.name2': 'RefSeq Genes',
  //   'pos': 'Position',
  //   'chrom': 'Chromosome',
  // };

  Object.keys(defaultBoost).forEach(val => {
    defaultFieldsNotSearched[`${val}.exact`] = defaultBoost[val] * 2;
  });

  Object.defineProperty(searchState, "fieldNames", {
    get: () => _fieldNames
  });

  Object.defineProperty(searchState, "defaultFieldsNotSearched", {
    get: () => defaultFieldsNotSearched
  });

  Object.defineProperty(searchState, "defaultBoost", {
    get: () => defaultBoost
  });

  searchState.getMapping = getMapping;
  searchState.makeRefSeqLink = makeRefSeqLink;
  searchState.makePhenotypeIDlinks = makePhenotypeIDlinks;
  searchState.makeDbSnpLink = makeDbSnpLink;

  return searchState;

  /*
  User the ES configuration to identify fields that are parents, those that
  use the 'keyword' type and therefore match exactly (which can be used for aggregations)
  and which fields are numeric
  @param <Object>fieldObj : the ES configuration for a field
  @param <String>fields : the fieldName corresponding to the fieldObj
  @param <Object>fields : the fields accumulator (all fields)
  @param <Object>exactFields : the type "keyword" fields accumulator
  @param <Object>parentFields : the parent fields accumulator (parent.child)
  @param <Object>numericalFields : the numerical types accumulator
  @return <void>
  */
  function getMapping(
    fieldObj,
    fieldName,
    fields,
    exactFields,
    exactFieldsMinimal,
    parentFields,
    numericalFields,
    booleanFields
  ) {
    if (fieldObj["properties"]) {
      Object.keys(fieldObj["properties"]).forEach(key => {
        parentFields[fieldName] = 1;
        getMapping(
          fieldObj["properties"][key],
          `${fieldName}.${key}`,
          fields,
          exactFields,
          exactFieldsMinimal,
          parentFields,
          numericalFields
        );
      });
    } else {
      fields[fieldName] = 1;

      if (fieldObj["type"] === "keyword") {
        exactFields[fieldName] = fieldName;
        exactFieldsMinimal[fieldName] = fieldName;
      }

      if (_isNumericalEStype(fieldObj["type"])) {
        numericalFields[fieldName] = 1;
      } else if (fieldObj["type"] === "boolean") {
        booleanFields[fieldName] = 1;
      }

      // This is terminal; no need for
      if (fieldObj["fields"]) {
        Object.keys(fieldObj["fields"]).forEach(key => {
          let name = `${fieldName}.${key}`;

          fields[name] = 1;

          if (fieldObj["fields"][key]["type"] === "keyword") {
            exactFields[name] = name;
            // The field is fieldName; it's subfield mapping which is "exact", or allows it to be exact
            // is ${name}
            exactFields[fieldName] = name;
            exactFieldsMinimal[fieldName] = name;
          }

          if (_isNumericalEStype(fieldObj["type"])) {
            numericalFields[fieldName] = 1;
          } else if (fieldObj["type"] === "boolean") {
            booleanFields[fieldName] = 1;
          }
        });
      }
    }
  }

  // TODO: combine all NCBI link makes, differe only by path
  function makeRefSeqLink(term) {
    if (!term) {
      return;
    }
    // Because initializer for const is required
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/const
    let query;
    if (Array.isArray(term)) {
      query = term.join("+OR+");
    } else {
      query = term.replace(/[\;\|]/g, "+OR+");
    }
    return (
      "http://www.ncbi.nlm.nih.gov/nuccore/?term=" +
      query +
      "+AND+srcdb_refseq%5BPROP%5D"
    );
  }

  function makeDbSnpLink(term) {
    if (!term) {
      return;
    }
    // Because initializer for const is required
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/const
    let query;
    if (Array.isArray(term)) {
      query = term.join("+OR+");
    } else {
      query = term.replace(/[\;\|]/g, "+OR+");
    }
    return "http://www.ncbi.nlm.nih.gov/snp/?term=" + query;
  }

  function makePhenotypeIDlinks(phenotypeIDs) {
    return phenotypeIDs
      .split(alleleDelimiter)
      .map(alleleVal => {
        return alleleVal
          .split(positionDelimiter)
          .map(positionVal => {
            return positionVal
              .split(valueDelimiter)
              .map(val => {
                const link = _generateLink(val);
                if (link) {
                  return `<a href='${link}' target='_blank'><strong>${val}</strong></a>`;
                } else {
                  return `<strong>${val}</strong>`;
                }
              })
              .join(valueDelimiter);
          })
          .join(positionDelimiter);
      })
      .join(alleleDelimiter);
  }

  function _generateLink(linkValue) {
    if (linkValue.indexOf("OMIM:") > -1) {
      const index = linkValue.indexOf(":");
      return `http://omim.org/entry/${linkValue.substr(index + 1)}`;
    }

    if (linkValue.indexOf("MedGen:") > -1) {
      const index = linkValue.indexOf(":");
      return `https://www.ncbi.nlm.nih.gov/medgen/?term=${linkValue.substr(
        index + 1
      )}`;
    }

    return null;
  }

  /*Private*/
  /*Identify whether a given <type> is a valid ES numerical datatype
  @param <String>type : the ES type
  @return <Bool>
  */
  function _isNumericalEStype(type) {
    return (
      type === "long" ||
      type === "integer" ||
      type === "short" ||
      type === "byte" ||
      type === "double" ||
      type === "float" ||
      type === "half_float" ||
      type === "scaled_float"
    );
  }
}
